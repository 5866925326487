<template>
  <div>
    <v-icon
      color="success"
      @click="handleDeliveredModal"
      title="Marcar como entregue"
      v-if="!cte.delivered"
    >
      mdi-check
    </v-icon>
    <div v-if="deliveredModal">
      <v-dialog v-model="deliveredModal">
        <v-card>
          <v-card-title>Confirmar entrega</v-card-title>
          <v-card-text>
            <p
              v-if="cte.delivered"
              class="red--text yellow text-center font-weight-bold"
            >
              ATENÇÃO: Cte já tem data de entrega, salvar irá sobrescrer a
              mesma!
            </p>
            <v-row>
              <v-col cols="12" md="4">
                <span><strong>CTe:</strong> {{ cte.number }}</span>
              </v-col>
              <v-col cols="12" md="4">
                <span><strong>NF:</strong> {{ cte.nf }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3"
                ><span><strong>Volumes: </strong>{{ cte.qty }}</span></v-col
              >
              <v-col cols="12" md="3"
                ><span><strong>Peso: </strong>{{ cte.weigth }}</span></v-col
              >
              <v-col cols="12" md="3"
                ><span
                  ><strong>Valor NF: </strong
                  >{{ $functions.moneyFormat(cte.nf_value) }}</span
                ></v-col
              >
              <v-col cols="12" md="3"
                ><span
                  ><strong>Frete: </strong
                  >{{ $functions.moneyFormat(cte.freight_value) }}</span
                ></v-col
              >
            </v-row>
            <br />
            <hr />
            <br />
            <v-form ref="saveDeliveryForm" @submit.prevent="save()">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Data de entrega"
                    type="date"
                    v-model="delivery_day"
                    :rules="[(v) => !!v || 'Este campo é obrigatório']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    class="mt-4"
                    :loading="saving"
                    color="success"
                    type="submit"
                    >Salvar</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cte: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      deliveredModal: false,
      delivery_day: new Date().toISOString().slice(0, 10),
      saving: false,
    };
  },
  methods: {
    handleDeliveredModal() {
      let has = false;

      this.cte.occurrences.forEach(occurrence => {
        if(occurrence.closed === 0) {
          has = true;
        }
      });

      if(has) {
        this.$toast.error('Este cte possui ocorrências abertas');

        return;
      }

      this.deliveredModal = true;
    },
    save() {
      this.saving = true;
      if (this.$refs.saveDeliveryForm.validate()) {
        this.$http
          .post("api/set-delivered/" + this.cte.id, {
            date: this.delivery_day,
          })
          .then(() => {
            this.saving = false;
            this.$toast.success('Salvo com sucesso');
            this.deliveredModal = false;
            this.$emit("deliveryDateSaved", true);
          })
          .catch((err) => {
            this.saving = false;
            if(err.response.status) {
              this.$toast.error('CTe com ocorrência aberta');
            } else {
              this.$toast.error('Erro ao salvar');
            }
          });
      }
    },
  },
};
</script>

<style scoped>
span {
  font-size: 18px;
}
</style>
