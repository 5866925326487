<template>
  <div>
    <v-dialog v-model="opened" persistent>
      <v-card>
        <v-card-title>Editar Romaneio</v-card-title>
        <v-card-text>
          <v-form :loading="loading" @submit.prevent="handleUpdate">
            <v-select 
              label="Motorista"
              :items="drivers"
              item-text="name"
              item-value="id"
              :loading="drivers.length < 1"
              v-model="delivery.driver_id"
              :rules="rules"
            />
            <v-text-field
              type="date"
              label="Data"
              v-model="delivery.date"
              :rules="rules"
            />
            <v-btn :loading="loading" class="mx-1" color="success" type="submit">Salvar</v-btn>
            <v-btn class="mx-1" @click="handleClose" color="error">Cancelar</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      delivery: {
        type: Object,
        default: null,
      },
    },
    mounted() {
      this.getDrivers();
    },
    data() {
      return {
        opened: false,
        drivers: [],
        rules: [
          v => !! v || "Este campo é obrigatório"
        ],
        loading: false,
      }
    },
    methods: {
      getDrivers() {
        this.$http.get(`api/drivers`)
          .then(({data}) => this.drivers = data)
          .catch(err => this.$toast.error('Erro ao carregar motoristas'));
      },
      openModal() {
        this.opened = true;
      },
      handleUpdate() {
        this.loading = true;

        this.$http.put(`api/deliveries/${this.delivery.id}`, this.delivery)
          .then(() => {
            this.loading = false;
            this.$toast.success('Salvo com sucesso');
            this.opened = false;
            this.$emit('updated');
          }).catch(err => {
            console.log(err);
            this.loading = false;
            this.$toast.error('Erro ao salvar');
          });
      },
      handleClose() {
        this.opened = false;
        this.$emit('updated');
      },
    }
  }
</script>
