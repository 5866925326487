<template>
  <div>
    <v-btn
      @click="setDelivered"
      color="success"
      fab
      small
      title="Marcar tudo como entregue"
      :disabled="loading"
    >
      <v-icon>mdi-check</v-icon>
    </v-btn>

    <v-btn
      @click="setNotDelivered"
      color="warning"
      fab
      small
      title="Marcar tudo como não entregue"
      class="mx-2"
      :disabled="loading"
      v-if="$auth.isAuthorized('manager')"
    >
      <v-icon>mdi-restore</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    ctes: {
      type: Array,
      default: null
    },
    date: {
      type: String,
      default: new Date().toISOString().slice(0,10)
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    setDelivered() {
      let has = false;
      let ctesWithOccurrences = [];

      this.ctes.forEach(cte => {
        cte.ctes.occurrences.forEach(occurrence => {
          if(occurrence.closed === 0) {
            has = true;
            ctesWithOccurrences.push(cte.ctes.number);
          }
        });
      });

      if(has) {
        let ctesInList = ctesWithOccurrences.join(', ');
        this.$toast.error(`Os ctes ${ ctesInList } tem ocorrência aberta`);

        return;
      }

      if(confirm('***ATENÇÃO*** Tem certeza que deseja marcar tudo como entregue?')) {
        this.loading = true;
        this.$http.post('api/all-deliveries-delivered', {ctes: this.ctes, date: this.date})
        .then(() => {
          this.loading = false;
          this.$side({
            type    : 'success',
            msg     : 'Salvo com sucesso',
            duration: 4000
          });

          this.$emit('setDelivered');
        }).catch(err => {
          this.loading = false;
          this.$side({
            type    : 'error',
            msg     : 'Erro ao marcar como entregue',
            duration: 4000
          });

          console.log(err);
        });
      }
    },

    setNotDelivered() {
      if(confirm('***ATENÇÃO*** Tem certeza que deseja marcar tudo como NÃO entregue?')) {
        this.loading = true;
        this.$http.post('api/all-deliveries-not-delivered', {ctes: this.ctes})
        .then(() => {
          this.loading = false;
          this.$side({
            type    : 'success',
            msg     : 'Salvo com sucesso',
            duration: 4000
          });

          this.$emit('setDelivered');
        }).catch(err => {
          this.loading = false;
          this.$side({
            type    : 'error',
            msg     : 'Erro ao marcar como entregue',
            duration: 4000
          });

          console.log(err);
        });
      }
    }
  },
};
</script>